import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles/";
import Layout from "../components/layout";
import { ContactModal } from "../components/contactModal";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: "2rem",
    },
    paragraph: {
        margin: "1rem auto",
        [theme.breakpoints.up("lg")]: {
            maxWidth: "70vw",
        },
    },
    imageContainer: {
        textAlign: "center",
    },
    image: {
        width: "90%",
        margin: "auto",
    },
}));

export default function Invest() {
    const classes = useStyles();
    return (
        <Layout>
            <Grid container alignItems="center" className={classes.container}>
                <Grid
                    container
                    item
                    sm={12}
                    md={6}
                    className={classes.imageContainer}
                >
                    <img
                        src="/invest_house.svg"
                        alt="invest_house"
                        className={classes.image}
                    />
                </Grid>
                <Grid container item sm={12} md={6}>
                    <Grid item className={classes.paragraph}>
                        <Typography variant="h3">
                            Finding the Right Opportunity
                        </Typography>
                    </Grid>
                    <Grid item className={classes.paragraph}>
                        <Typography variant="h4">
                            Investment Considerations
                        </Typography>
                        <Typography>
                            Even though today’s house prices still remain high
                            in most markets (i.e., Greater Toronto Area )
                            mortgage interest costs are at historic lows. And
                            owning your own home has a number of advantages. For
                            example, owning your house is a great tax shelter.
                            That’s because gains on your principal residence are
                            exempt from capital-gains taxes. Note, though, that
                            this benefit only applies to your principal
                            residence. You must still pay tax on gains on the
                            sale of a recreational property, such as a cottage
                            or a ski chalet. But these properties generally
                            appreciate at a much slower rate than, say, a home
                            in a major urban centre.
                        </Typography>
                    </Grid>
                    <Grid item className={classes.paragraph}>
                        <Typography variant="h4">How I can Help</Typography>
                        <Typography>
                            Similar to your principal residence, you’ll want to
                            purchase a rental property in a decent neighborhood
                            that’s attractive to tenants. Quality tenants will
                            be attracted to your property if a major employer,
                            like a hospital, is nearby. While a rental property
                            is certainly an active investment, you can choose to
                            be as involved as you like. With a myriad of
                            resources at my behest, I can find the best
                            investment opportunity for you.
                        </Typography>
                        <Typography>
                            Set up a session with me today for a
                            <strong> free consultation </strong>!!!
                        </Typography>
                    </Grid>
                    <ContactModal></ContactModal>
                </Grid>
            </Grid>
        </Layout>
    );
}
